import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby";
import Layout from "../components/layout";
import JSONPretty from 'react-json-pretty';

export const query = graphql`
query($slug: String!) {
  allDexJson(filter: { name: { eq: $slug } }) {
	nodes {
		name
		types
	  	dexNo {
			galar
			national
	  	}
		abilities
	  	evolutions {
			method
			name
	  	}
		stats {
			atk
			def
			hp
			spAtk
			spDef
			spe
			total
		}
	  	eggGroups
	  	maxExp
	  	moves {
			method
			name
		}
	}
  }
  allMovesJson {
    nodes {
      accuracy
      category
      name
      power
      type
    }
  }
  allFile(filter: { name: { eq: $slug } }) {
	nodes {
		publicURL
		name
	}
  }
}`

const DexEntryPage = (props) => {
	const pokemon = { ...props.data.allDexJson.nodes[0] };
	const moves = props.data.allMovesJson.nodes;

	pokemon.moves = pokemon.moves.map(move => {
		const fullMove = moves.find(({ name }) => name === move.name);
		return {
			...fullMove,
			...move
		};
	})

	return (
		<Layout>
			<Entry pokemon={pokemon} />
			<Link to="/">Go to dex page</Link>
		</Layout>
	)
}

const Entry = (props) => {
	return(
		<>
			<JSONPretty data={JSON.stringify(props)} />
		</>
	);
}

export default DexEntryPage;
